import { stringToArrayBuffer } from "@/utils/crypto";
import { KeyPairEncrypted, Salt } from "../../../types/cryptography";
import { decryptSecret, generateKeyPair, KEY_PAIR_ALGORITHM } from "../systems/asymmetric";
import { generateRandomSalt, generateSecretKey, importSecret } from "../systems/symmetric";

type GenerateWrappedKeyPairFn = (props: { secret: string }) => Promise<{
  encryptedKeyPair: KeyPairEncrypted["keyPair"];
  keyPair: CryptoKeyPair;
  salt: Salt;
}>;

type WrapKeyPairFn = (props: { keyPair: CryptoKeyPair; salt: Salt; secret: string }) => Promise<{
  encryptedKeyPair: KeyPairEncrypted["keyPair"];
  keyPair: CryptoKeyPair;
  salt: Salt;
}>;

/**
 * Generates a new key pair and encrypts it using the user's password.
 * @param password - User's password for logging in.
 * @returns Key pair (encrypted and plain), plus salt for decrypting key pair.
 */
export const generateWrappedKeyPair: GenerateWrappedKeyPairFn = async ({ secret }) => {
  const keyPair = await generateKeyPair();
  const salt = generateRandomSalt();

  return await wrapKeyPair({
    keyPair,
    salt,
    secret,
  });
};

export const wrapKeyPair: WrapKeyPairFn = async ({ keyPair, salt, secret }) => {
  const secretKey = await generateSecretKey(secret, salt);
  const algorithm: AesGcmParams = {
    name: "AES-GCM",
    iv: salt,
  };

  const encryptedKeyPair = {
    privateKey: await crypto.subtle.wrapKey("pkcs8", keyPair.privateKey, secretKey, algorithm),
    publicKey: await crypto.subtle.wrapKey("spki", keyPair.publicKey, secretKey, algorithm),
  };

  return {
    encryptedKeyPair,
    keyPair,
    salt,
  };
};

/**
 * Decrypts an encrypted key pair.
 * @param encryptedKeyPair - Private/public key pair, encrypted with user's secret key.
 * @param password - Password user logs in with.
 * @param salt - Previously generated salt.
 * @returns Unencrypted key pair for user actions.
 */
export async function unwrapEncryptedKeyPair(
  encryptedKeyPair: KeyPairEncrypted["keyPair"],
  password: string,
  salt: Salt
): Promise<CryptoKeyPair> {
  const secretKey = await generateSecretKey(password, salt);

  const algorithm: AesGcmParams = {
    name: "AES-GCM",
    iv: salt,
  };

  return {
    privateKey: await crypto.subtle.unwrapKey(
      "pkcs8",
      encryptedKeyPair.privateKey,
      secretKey,
      algorithm,
      KEY_PAIR_ALGORITHM,
      true,
      ["decrypt"]
    ),
    publicKey: await crypto.subtle.unwrapKey(
      "spki",
      encryptedKeyPair.publicKey,
      secretKey,
      algorithm,
      KEY_PAIR_ALGORITHM,
      true,
      ["encrypt"]
    ),
  };
}

export async function getSecretKey(privateKey: CryptoKey, secretEncrypted: string) {
  const decryptedSecret = await decryptSecret({
    privateKey,
    secret: stringToArrayBuffer(secretEncrypted),
  });

  return await importSecret(decryptedSecret);
}
