import { HomeDashboard } from "@/features/home_dashboard";
import { defaultAbcRiskBandsConfig, defaultInputValues } from "@/features/risk_bands";
import { HubTheme } from "../../types";

const defaultTheme: HubTheme = {
  meta: {
    name: "Hub",
    aesthetics: {
      attribution: "none",
      authorisation: {
        signUp: {},
      },
    },
    config: {
      featureSet: {
        comms: {
          feedback: {
            enabled: false,
          },
          support: {
            enabled: false,
          },
          emailSupport: {
            enabled: false,
            email: "support@xdi.systems",
          },
        },
        docs: {
          accountManagementTutorial: {
            enabled: false,
          },
          changeLog: {
            enabled: false,
          },
          contextualElementTutorials: {
            enabled: false,
            overrides: {},
          },
          knowledgeBase: {
            glossary: {
              enabled: false,
            },
            method: {
              enabled: false,
              publicDocsUrl: "https://learn.xdi.systems/methodology-documentation",
            },
          },
          legal: {
            privacyPolicy: "https://xdi.systems/privacy-policy",
            termsOfService: "https://xdi.systems/terms-of-service",
          },
          toolPageTutorials: {
            enabled: false,
          },
        },
        settings: {
          enabled: false,
        },
        tools: [
          /* These must be set up within each theme for optimal tree-shaking. */
        ],
        tracking: {
          hubspot: {
            enabled: false,
          },
          plausible: {
            enabled: false,
          },
        },
      },
      geocoding: {
        allowCoordinatesInQuickBatchScreen: true,
        allowReordering: false,
        confidenceThreshold: 65,
        coordinateRestrictions: {
          showBoundsOnMap: false,
        },
        defaultMapCentre: {
          lat: -33.7970596,
          lng: 151.1785891,
        },
        defaultOrder: ["google"],
        locationRestrictions: {},
        minLengthForSuggestions: 5,
      },
      inputConstraints: {
        addressLength: { min: 8, max: 200 },
        buildYear: { min: 1600, max: 2100 },
        // floor below 0 does not work with structural analysis; see products
        floorHeight: { min: 0, max: 10000 },
      },
      locale: {
        defaultInputValues: defaultInputValues.aud.residential,
        defaultLanguage: "en-AU",
        supportedLanguages: ["en-AU"],
      },
      pages: {
        home: HomeDashboard,
      },
      riskBands: defaultAbcRiskBandsConfig,
      portfolioExport: {
        assetLevelDeepDive: {
          outputTypes: ["screen", "small", "tall"],
        },
        largeBank: {
          minAddresses: 0,
        },
        maxAddresses: 20000,
        template: {
          address: "385 Bourke St, Melbourne VIC 3000",
          centre: {
            lat: -37.8148594,
            lng: 144.9629097,
          },
        },
      },
    },
  },
  antd: {
    token: {
      colorTextDescription: "#717171",
      colorLink: "#136be5",
      linkFocusDecoration: "underline",
    },
    components: {
      Layout: {
        bodyBg: "transparent",
        headerBg: "#fafafa",
        padding: 0,
      },
      Menu: {
        itemBg: "transparent",
        itemSelectedColor: "#303030",
        lineWidth: 0,
      },
      Input: {
        borderRadius: 8,
      },
      Select: {
        borderRadius: 8,
      },
      Button: {
        borderRadius: 8,
      },
    },
  },
};

export default defaultTheme;
