import { Avatar } from "@/components/buttons/Avatar";
import supabase from "@/features/supabase";
import { useKeys } from "@/utils/hooks/useKeys";
import { LockOutlined, UnlockOutlined } from "@ant-design/icons";
import { useSession } from "@supabase/auth-helpers-react";
import { Button, Form, Input, Skeleton } from "antd";
import { useState } from "react";
const UserPasswordInput = ({
  prompt = "Your portfolios are locked. Verify your identity to continue.",
  isLoading = false
}: {
  prompt?: string;
  isLoading?: boolean;
}) => {
  const session = useSession();
  const [, {
    getAllKeys
  }] = useKeys();
  const [error, setError] = useState("");
  const handlePasswordSubmit = async ({
    password
  }: {
    password: string;
  }) => {
    const res = await getAllKeys({
      password
    });
    console.log(res.error);
    if (res.error) {
      setError("message" in res.error ? res.error.message : "Your password is invalid. Please try again.");
    }
  };
  const handleLogout = async () => {
    await supabase.auth.signOut();
  };
  return <>
      <div className="relative flex h-full w-full items-center justify-center">
        <div className="absolute top-0 -z-10 w-full overflow-hidden opacity-50 blur-sm">
          {Array.from({
          length: 4
        }).map((_, idx) => <Skeleton active key={idx} />)}
        </div>
        <div className="mx-auto my-0 mt-[128px] w-full max-w-lg rounded-2xl border border-gray-200 bg-gray-50 p-8 shadow-xl">
          <div className="mx-auto my-0 mb-4 flex h-16 w-16 items-center justify-center rounded-xl border border-gray-200 bg-white text-gray-900 shadow-lg shadow-gray-200">
            <LockOutlined className="text-2xl" data-sentry-element="LockOutlined" data-sentry-source-file="UserPasswordInput.tsx" />
          </div>
          <div className="mb-4 text-center text-base leading-relaxed">{prompt}</div>

          <Form layout="vertical" requiredMark={false} onFinish={handlePasswordSubmit} fields={[{
          name: ["email"],
          value: session?.user.email
        }]} onBlur={() => setError("")} data-sentry-element="Form" data-sentry-source-file="UserPasswordInput.tsx">
            <div className="mx-auto mb-4 flex w-max items-center gap-2 rounded-lg border border-gray-200 p-1 pr-2 text-center text-sm">
              <Avatar name={session?.user.email || ""} size={20} data-sentry-element="Avatar" data-sentry-source-file="UserPasswordInput.tsx" />
              {session?.user.email}
            </div>
            <Form.Item name="password" rules={[{
            required: true,
            message: "Password is required"
          }]} className="mb-6" data-sentry-element="unknown" data-sentry-source-file="UserPasswordInput.tsx">
              <Input.Password prefix={<LockOutlined />} placeholder="Password" type="password" data-sentry-element="unknown" data-sentry-source-file="UserPasswordInput.tsx" />
            </Form.Item>

            {error && <div className="pb-2 text-center text-red-500">{error}</div>}

            <Form.Item className="mb-2" data-sentry-element="unknown" data-sentry-source-file="UserPasswordInput.tsx">
              <Button type="primary" block htmlType="submit" loading={isLoading} icon={<UnlockOutlined />} data-sentry-element="Button" data-sentry-source-file="UserPasswordInput.tsx">
                Unlock
              </Button>
            </Form.Item>
            <Form.Item className="mb-2" data-sentry-element="unknown" data-sentry-source-file="UserPasswordInput.tsx">
              <Button type="text" block onClick={handleLogout} data-sentry-element="Button" data-sentry-source-file="UserPasswordInput.tsx">
                Log out
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </>;
};
export default UserPasswordInput;