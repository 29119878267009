export type KeyPairSessionStorage = {
  public: string;
  private: string;
  salt: string;
};

export const KEY_PAIR_STORE: { user: KeyPairSessionStorage; org: KeyPairSessionStorage; orgSecret: string } = {
  user: {
    public: "upu",
    private: "upr",
    salt: "us",
  },
  org: {
    public: "opu",
    private: "opr",
    salt: "os",
  },
  orgSecret: "ose",
};

export const ERROR_TYPES = {
  EMPTY_RESPONSE: "EMPTY_RESPONSE",
  INCORRECT_PASSWORD: "INCORRECT_PASSWORD",
  FETCH_ERROR: "FETCH_ERROR",
  INVALID_KEY_OR_SECRET: "INVALID_KEY_OR_SECRET",
};
