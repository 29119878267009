import { getCommsConfig } from "@/features/branding";
import { store } from "@/store";
import { supabaseApi } from "@/store/services/supabase";
import { getCurrentBuildCode } from "@/utils/versioning";
import { WarningTwoTone } from "@ant-design/icons";
import { useUser } from "@supabase/auth-helpers-react";
import { Button, Modal, ModalProps } from "antd";
import Link from "next/link";
import { useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import styles from "./AcceptTerms.module.scss";
enum ModalState {
  CLOSED,
  FIRST_TIME,
  VERSION_UPDATE,
}
export const AcceptTerms = () => {
  const user = useUser();
  const currentBuildCode = getCurrentBuildCode();
  const [state, setState] = useState<ModalState>(ModalState.CLOSED);

  // Closes the modal, updates the version in the database.
  const closeAndUpdate = () => {
    store.dispatch(supabaseApi.endpoints.updateLastVersionAccessed.initiate(currentBuildCode));
    setState(ModalState.CLOSED);
  };
  useEffect(() => {
    // We are having difficulties getting past the modals under CI users.
    if (user?.email === "ci@climati.ca") {
      return;
    }
    store.dispatch(supabaseApi.endpoints.getLastVersionAccessed.initiate(undefined, {
      forceRefetch: true
    })).then(({
      data: dbVersion
    }) => {
      if (!dbVersion) {
        setState(ModalState.FIRST_TIME);
        return;
      }
      if (dbVersion !== currentBuildCode) {
        setState(ModalState.VERSION_UPDATE);
      }
    });
  }, []);
  return <>
      <FirstTimeModal open={state === ModalState.FIRST_TIME} closeAndUpdate={closeAndUpdate} data-sentry-element="FirstTimeModal" data-sentry-source-file="AcceptTerms.tsx" />
      <VersionUpdateModal open={state === ModalState.VERSION_UPDATE} closeAndUpdate={closeAndUpdate} data-sentry-element="VersionUpdateModal" data-sentry-source-file="AcceptTerms.tsx" />
    </>;
};
interface FirstTimeModalProps extends ModalProps {
  closeAndUpdate: () => void;
}
const FirstTimeModal = (props: FirstTimeModalProps) => {
  const {
    t
  } = useTranslation("widgets", {
    keyPrefix: "firstTimeLogin"
  });
  return <Modal closable={false} title={<div className={styles.titleContainer}>
          <h2 className={styles.title}>{t("title")}</h2>
          <WarningTwoTone className={styles.titleIcon} twoToneColor={"#f59e0b"} />
        </div>} footer={<Button onClick={props.closeAndUpdate} type="primary" block size="large">
          {t("button")}
        </Button>} {...props} data-sentry-element="Modal" data-sentry-component="FirstTimeModal" data-sentry-source-file="AcceptTerms.tsx">
      <div className={styles.blurbContainer}>
        <p className={styles.blurb}>
          <strong>{t("warning")}</strong>
        </p>
        {t("body").split("\n\n").map((text, index) => <p key={index} className={styles.blurb + " !mt-2"}>
              {text}
            </p>)}
      </div>
    </Modal>;
};
interface VersionUpdateModalProps extends ModalProps {
  closeAndUpdate: () => void;
}
const VersionUpdateModal = (props: VersionUpdateModalProps) => {
  const {
    t
  } = useTranslation("widgets", {
    keyPrefix: "versionUpdate"
  });
  const supportEmail = getCommsConfig().emailSupport.email;
  return <Modal closable={true} onCancel={props.closeAndUpdate} onClose={props.closeAndUpdate} title={<div className={styles.titleContainer}>
          <h2 className={styles.title}>{t("title")}</h2>
        </div>} footer={<Button onClick={props.closeAndUpdate} type="primary" block size="large">
          {t("button")}
        </Button>} {...props} data-sentry-element="Modal" data-sentry-component="VersionUpdateModal" data-sentry-source-file="AcceptTerms.tsx">
      <div className={styles.blurbContainer}>
        <Trans i18nKey="widgets:versionUpdate.body" components={{
        p: <p className={styles.blurb + " !mt-2"} />,
        a: supportEmail ? <Link href={`mailto:${supportEmail}`} target="__blank" /> : <span />
      }} data-sentry-element="Trans" data-sentry-source-file="AcceptTerms.tsx" />
      </div>
    </Modal>;
};