import { PortfolioExportJobSchema } from "@/tools/aggregate/portfolio-export/types";
import { DeleteOutlined, DownOutlined, StopOutlined } from "@ant-design/icons";
import { Button, Dropdown, MenuProps, Popconfirm } from "antd";
import { useState } from "react";
import { JobSchema } from "../types/JobSchema";
import styles from "./JobActions.module.scss";
import { JobFailModal, JobFailModalProps } from "./JobFailModal";
interface Item {
  label: string;
  onClick: () => void;
  disabled?: boolean;
  icon?: JSX.Element;
  key?: string;
}
export interface JobActionsProps<T extends JobSchema> {
  completedActions: Item[];
  onDeleteJob: () => void;
  onEditJob?: (props: {
    schema: PortfolioExportJobSchema;
    name: string;
    isPrivate: boolean;
    groupId: string;
  }) => Promise<void>;
  schema: T;
  status?: string;
}
export const JobActions = <T extends JobSchema,>({
  completedActions,
  onDeleteJob,
  schema,
  status
}: JobActionsProps<T>) => {
  // Do not show any actions if we cannot determine the status of a job.
  if (!status) {
    return <></>;
  }
  switch (status) {
    case "completed":
      return <DropdownButtonFromList items={completedActions} />;
    case "failed":
      return <FailedJobActions onDeleteJob={onDeleteJob} schema={schema} />;
    default:
      return <Popconfirm title="This job has not yet completed. Are you sure you want to stop it?" onConfirm={onDeleteJob} cancelText="No" okText="Yes">
          <Button block>
            <StopOutlined /> Stop Job
          </Button>
        </Popconfirm>;
  }
};
interface FailedJobActionsProps<T extends JobSchema> {
  onDeleteJob: () => void;
  schema: Required<T>;
  renderFailModal?: (props: JobFailModalProps<T>) => void;
}
export const FailedJobActions = <T extends JobSchema,>(props: FailedJobActionsProps<T>) => {
  const [failModalOpen, setFailModalOpen] = useState(false);
  const items: MenuProps["items"] = [{
    icon: <DeleteOutlined />,
    label: "Delete Job",
    key: "delete",
    onClick: props.onDeleteJob
  }];
  const defaultRenderFailModal = (props: JobFailModalProps<T>) => {
    return <JobFailModal schema={props.schema} onCancel={props.onCancel} open={props.open} data-sentry-element="JobFailModal" data-sentry-component="defaultRenderFailModal" data-sentry-source-file="JobActions.tsx" />;
  };
  const renderFailModal = props.renderFailModal || defaultRenderFailModal;
  return <>
      <Dropdown.Button className={styles.dropdown} icon={<DownOutlined />} menu={{
      items
    }} onClick={() => setFailModalOpen(true)} trigger={["click"]} data-sentry-element="unknown" data-sentry-source-file="JobActions.tsx">
        View Error
      </Dropdown.Button>
      {renderFailModal({
      schema: props.schema,
      onCancel: () => setFailModalOpen(false),
      open: failModalOpen
    })}
    </>;
};
interface DropdownButtonFromListProps {
  items: Item[];
}
const DropdownButtonFromList = ({
  items
}: DropdownButtonFromListProps) => {
  if (!items.length) {
    return <></>;
  }
  const itemList = [...items];
  const {
    disabled,
    icon,
    label,
    onClick
  } = itemList.shift()!;
  return <Dropdown.Button className={styles.dropdown} disabled={disabled} icon={<DownOutlined />} menu={{
    items: itemList as any[]
  }} trigger={["click"]} onClick={onClick} data-sentry-element="unknown" data-sentry-component="DropdownButtonFromList" data-sentry-source-file="JobActions.tsx">
      {icon} {label}
    </Dropdown.Button>;
};