import supabase from "@/features/supabase";
import { store } from "@/store";
import { delay } from "@/utils/promises";
import { FetchBaseQueryError } from "@reduxjs/toolkit/query";
import {
  AuthError,
  FunctionsFetchError,
  FunctionsHttpError,
  FunctionsRelayError,
  PostgrestError,
  PostgrestResponse,
} from "@supabase/supabase-js";

export interface CustomError {
  message: string;
  data?: unknown;
}

export const getSupabaseError = (
  error: PostgrestResponse<"PostgrestResponseFailure"> | PostgrestError | AuthError | CustomError
): FetchBaseQueryError => {
  return {
    status: "CUSTOM_ERROR",
    error: "statusText" in error ? error.statusText : error?.message || "Unknown Error",
    data: { ...error },
  };
};

type FuncErrTypes = FunctionsHttpError | FunctionsRelayError | FunctionsFetchError;

export const getSupabaseFunctionError = async (error: FuncErrTypes): Promise<FetchBaseQueryError> => {
  const funcErr: FetchBaseQueryError = { status: "CUSTOM_ERROR", error: "", data: "" };
  if (error instanceof FunctionsHttpError) {
    funcErr.error = "Error";
    funcErr.data = (await error.context.json()).error; // _our_ error returned by function
  } else if (error instanceof FunctionsRelayError) {
    funcErr.error = "Intermittent Error";
    funcErr.data = `Please try again shortly. (${error.message})`;
  } else if (error instanceof FunctionsFetchError) {
    funcErr.error = "Network Error";
    funcErr.data = `You may not be connected to the Internet. (${error.message})`;
  }
  console.error(funcErr);
  return funcErr;
};

type WithRetriesType = {
  action: any;
  maxRetries?: number;
  timeout?: number;
};

export function dispatchWithRetries<T>({ action, maxRetries = 5, timeout = 2000 }: WithRetriesType) {
  let attempt = 1;

  const tryFetching = async (): Promise<T> => {
    return await store
      .dispatch(action)
      .unwrap()
      .then((data: T) => data)
      .catch(async (err: any) => {
        console.log(`Failed fetch: Attempt ${attempt}/${maxRetries}. Retrying in ${timeout}ms.`, err);
        await delay(timeout);
        attempt++;
        if (attempt > maxRetries) throw err;

        return tryFetching();
      });
  };

  return tryFetching();
}

export const getSession = async () => {
  const session = await supabase.auth.getSession();
  if (!session.data.session || session.error) {
    return {
      session: null,
      sessionError: getSupabaseError(session.error || { message: "Session not found" }),
    };
  }

  return {
    session: session.data.session,
    sessionError: null,
  };
};
