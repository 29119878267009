import supabase, { SupabaseDatabase } from "@/features/supabase";
import { supabaseApi } from "@/store/services/supabase";
import { getSession, getSupabaseError } from "@/store/services/supabase/utils";

type UserTable = SupabaseDatabase["public"]["Tables"]["users"]["Row"];
type UserGroupTable = SupabaseDatabase["public"]["Tables"]["users_groups"]["Row"];
type OrgTable = SupabaseDatabase["public"]["Tables"]["orgs"]["Row"];

export const keysApi = supabaseApi.injectEndpoints({
  endpoints: (builder) => ({
    addUserKeyPair: builder.mutation<null, Pick<UserTable, "keys_enc" | "public_key">>({
      invalidatesTags: [{ type: "Keys", id: "USER" }],
      queryFn: async ({ public_key, keys_enc }) => {
        const { session, sessionError } = await getSession();
        if (sessionError) return { error: sessionError };

        const { data, error } = await supabase
          .from("users")
          .update({ public_key, keys_enc })
          .filter("id", "eq", session.user.id);

        if (error) {
          return { error: getSupabaseError(error) };
        }
        return { data };
      },
    }),

    addUserOrgSecretFor: builder.mutation<null, { id: string } & Pick<UserGroupTable, "org_secret_enc">>({
      invalidatesTags: [
        { type: "User", id: "LIST" },
        { type: "User", id: "PENDING_COuNT" },
        { type: "Keys", id: "ORG_SECRET" },
      ],
      queryFn: async ({ org_secret_enc, ...props }) => {
        const { data, error } = await supabase
          .from("users_groups")
          .update({ org_secret_enc })
          .filter("user_id", "eq", props.id);
        // .filter("group_id", "eq", group_id)

        if (error) {
          return { error: getSupabaseError(error) };
        }
        return { data };
      },
    }),

    addUserOrgSecret: builder.mutation<
      SupabaseDatabase["public"]["Functions"]["add_user_org_secret"]["Returns"],
      SupabaseDatabase["public"]["Functions"]["add_user_org_secret"]["Args"]
    >({
      invalidatesTags: [{ type: "Keys", id: "ORG_SECRET" }],
      queryFn: async ({ org_secret_enc }) => {
        const { data, error } = await supabase.rpc("add_user_org_secret", {
          org_secret_enc: org_secret_enc || "",
        });

        if (error) {
          return { error: getSupabaseError(error) };
        }
        return { data };
      },
    }),

    getUserOrgSecret: builder.query<Pick<UserGroupTable, "org_secret_enc">, void>({
      providesTags: [{ type: "Keys", id: "ORG_SECRET" }],
      queryFn: async () => {
        const { session, sessionError } = await getSession();
        if (sessionError) return { error: sessionError };

        const { data, error } = await supabase
          .from("users_groups")
          .select("org_secret_enc")
          .filter("user_id", "eq", session.user.id)
          // .filter("group_id", "eq", group_id)
          .maybeSingle();

        if (!data || error) {
          return { error: getSupabaseError(error || { message: "Unable to find keys" }) };
        }
        return { data };
      },
    }),

    getUserKeyPair: builder.query<
      {
        keys_enc: string | null;
        public_key: string | null;
        users_groups: {
          org_secret_enc: string | null;
        }[];
      },
      void
    >({
      providesTags: [{ type: "Keys", id: "USER" }],
      queryFn: async () => {
        const { session, sessionError } = await getSession();
        if (sessionError) return { error: sessionError };

        const { data, error } = await supabase
          .from("users")
          .select("keys_enc, public_key, users_groups(org_secret_enc)")
          .filter("id", "eq", session.user.id)
          // .filter("users_groups.group_id", "eq", group_id)
          .maybeSingle();

        if (!data || error) {
          return { error: getSupabaseError(error || { message: "Unable to find keys" }) };
        }
        return { data };
      },
    }),

    getUserPublicKeyFor: builder.query<Pick<UserTable, "id" | "public_key">, { id?: string }>({
      providesTags: (props) => [{ type: "Keys", id: props?.id }],
      queryFn: async ({ id }) => {
        const { session, sessionError } = await getSession();
        if (sessionError) return { error: sessionError };

        const { data, error } = await supabase
          .from("users")
          .select("id, public_key")
          .filter("id", "eq", id || session.user.id)
          // .filter("users_groups.group_id", "eq", group_id)
          .maybeSingle();

        if (!data || error) {
          return { error: getSupabaseError(error || { message: "Unable to find keys" }) };
        }
        return { data };
      },
    }),

    addOrgKeyPair: builder.mutation<OrgTable, { public_key: string; keys_enc: string }>({
      invalidatesTags: [{ type: "Keys", id: "ORG" }],
      queryFn: async ({ public_key, keys_enc }) => {
        const { sessionError } = await getSession();
        if (sessionError) return { error: sessionError };

        const { data, error } = await supabase.rpc("add_org_keys", {
          public_key,
          keys_enc,
        });

        if (error) {
          return { error: getSupabaseError(error) };
        }
        return { data };
      },
    }),

    getOrgKeyPair: builder.query<Pick<OrgTable, "keys_enc" | "public_key">, void>({
      providesTags: [{ type: "Keys", id: "ORG" }],
      queryFn: async () => {
        const { data, error } = await supabase.from("orgs").select("keys_enc, public_key").maybeSingle();

        if (!data || error) {
          return { error: getSupabaseError(error || { message: "Unable to find keys" }) };
        }
        return { data };
      },
    }),

    canGenerateOrgKeys: builder.query<boolean, void>({
      queryFn: async () => {
        const { data, error } = await supabase.rpc("can_generate_org_keys");

        if (error) {
          return { error: getSupabaseError(error) };
        }
        return { data };
      },
    }),
  }),
});

export const {
  useGetUserOrgSecretQuery,
  useAddUserKeyPairMutation,
  useGetUserKeyPairQuery,
  useAddUserOrgSecretMutation,
  useAddUserOrgSecretForMutation,
  useLazyGetUserPublicKeyForQuery,
  useAddOrgKeyPairMutation,
  useGetOrgKeyPairQuery,
  useLazyGetOrgKeyPairQuery,
  useLazyCanGenerateOrgKeysQuery,
} = keysApi;
