import { Salt, SecretSalt } from "@/types/cryptography";
import { arrayBufferToString, stringToArrayBuffer } from "@/utils/crypto";

const decoder = new TextDecoder();
const encoder = new TextEncoder();

function buildAlgorithm(salt: Salt): AesGcmParams {
  return {
    name: "AES-GCM",
    iv: salt,
  };
}

/**
 * Imports a key from an input string which will be used to derive a useful key.
 * @param input - The string from which to derive a key. Usually a password.
 * @returns A key with which to derive a proper key.
 */
async function importKeyFromString(input: string) {
  return await crypto.subtle.importKey(
    "raw",
    encoder.encode(input),
    {
      name: "PBKDF2",
    },
    false,
    ["deriveKey"]
  );
}

async function deriveSecretKeyFromImportKey(importKey: CryptoKey, salt: Salt) {
  return await crypto.subtle.deriveKey(
    {
      name: "PBKDF2",
      hash: "SHA-256",
      salt,
      iterations: 600000,
    },
    importKey,
    {
      name: "AES-GCM",
      length: 256,
    },
    true,
    ["encrypt", "decrypt", "wrapKey", "unwrapKey"]
  );
}

export async function exportKey(key: CryptoKey) {
  return await crypto.subtle.exportKey("jwk", key);
}

/**
 * Encrypts data using provided key.
 * @returns Encrypted data.
 */
export async function encryptData(secret: SecretSalt, data: string) {
  const algorithm = buildAlgorithm(secret.salt);
  const encoded = encoder.encode(data);
  return await crypto.subtle.encrypt(algorithm, secret.secretKey, encoded);
}

/**
 * Decrypts encrypted string using provided key.
 * @returns The string its original form.
 */
export async function decryptData(secret: SecretSalt, data: ArrayBuffer): Promise<string> {
  const algorithm = buildAlgorithm(secret.salt);
  const decryptedData = await crypto.subtle.decrypt(algorithm, secret.secretKey, data);
  return decoder.decode(decryptedData);
}

export function generateRandomSalt(): Salt {
  return crypto.getRandomValues(new Uint8Array(16));
}

export async function generateSecretKey(input: string, salt: Salt) {
  const importKey = await importKeyFromString(input);
  return await deriveSecretKeyFromImportKey(importKey, salt);
}

export const generateRandomSecret = (length = 32) => {
  if (typeof window !== "undefined" && window.crypto && window.crypto.getRandomValues) {
    const randomValues = new Uint8Array(length);
    window.crypto.getRandomValues(randomValues);

    return Buffer.from(randomValues).toString("hex");
  } else {
    throw new Error("window.crypto not found");
  }
};

export const importSecret = async (secretAndSalt: string) => {
  const [secret, saltB64] = secretAndSalt.split(":");

  if (!secret || !saltB64) {
    throw new Error("Invalid secret key format");
  }

  const salt = stringToArrayBuffer(saltB64);

  const importKey = await importKeyFromString(secret);
  const secretKey = await deriveSecretKeyFromImportKey(importKey, salt);

  return {
    secretKey,
    salt,
    secretAndSalt,
  };
};

const exportSecret = async ({ secret, salt }: { secret: string; salt: Salt }) => {
  return `${secret}:${arrayBufferToString(salt)}`;
};

export const generateRandomSecretKey = async () => {
  const salt = generateRandomSalt();
  const secret = generateRandomSecret();
  const secretKey = await generateSecretKey(secret, salt);

  return {
    secret: {
      secretKey,
      salt,
    },
    exportedKey: await exportSecret({ secret, salt }),
  };
};
