/**
 * This is the standard public exponent. It is not recommended to change this.
 * Source: https://crypto.stackexchange.com/questions/3110/impacts-of-not-using-rsa-exponent-of-65537
 * Source: https://developer.mozilla.org/en-US/docs/Web/API/RsaHashedKeyGenParams
 */
export const RSA_PUBLIC_EXPONENT = new Uint8Array([1, 0, 1]);

export const KEY_PAIR_ALGORITHM: RsaHashedKeyAlgorithm = {
  name: "RSA-OAEP",
  modulusLength: 4096,
  publicExponent: RSA_PUBLIC_EXPONENT,
  hash: {
    name: "SHA-256",
  },
};

/**
 * Generates a key pair for asymmetric encryption.
 * @returns Newly generated private and public keys.
 */
export const generateKeyPair = async (): Promise<CryptoKeyPair> => {
  const keyUsages: readonly KeyUsage[] = ["encrypt", "decrypt"] as const;
  return await crypto.subtle.generateKey(KEY_PAIR_ALGORITHM, true, keyUsages);
};

/**
 * Encrypt string using asymmetric algorithm.
 * @param keyPair - public Key
 * @param data - data to be encrypted
 * @returns ArrayBuffer - encrypted data in binary format
 */
export async function encryptSecret({ publicKey, secret }: { publicKey: CryptoKey; secret: string }) {
  const enc = new TextEncoder();
  const encodedData = enc.encode(secret);

  return await crypto.subtle.encrypt(KEY_PAIR_ALGORITHM, publicKey, encodedData);
}

/**
 * Decrypt string encrypted with user/group key pair.
 * @param keyPair - User/Group Key Pair
 * @param data - data to be decrypted
 * @returns string - decrypted data
 */
export async function decryptSecret({ privateKey, secret }: { privateKey: CryptoKey; secret: ArrayBuffer }) {
  const decrypted = await crypto.subtle.decrypt(KEY_PAIR_ALGORITHM, privateKey, secret);

  const decoded = new TextDecoder().decode(decrypted);

  return decoded;
}

export async function importKey(key: string, keyUsage: KeyUsage[]) {
  return await crypto.subtle.importKey("jwk", JSON.parse(key), KEY_PAIR_ALGORITHM, true, keyUsage);
}

export async function exportKey(key: CryptoKey) {
  return await crypto.subtle.exportKey("jwk", key);
}
