import supabase from "@/features/supabase";
import { supabaseApi } from "@/store/services/supabase";
import { getSupabaseError } from "@/store/services/supabase/utils";
import { arrayBufferToHex } from "@/utils/crypto";

export const legacyKeysApi = supabaseApi.injectEndpoints({
  endpoints: (builder) => ({
    addLegacyKeyPair: builder.mutation<
      {
        keyPair: {
          publicKey: string;
          privateKey: string;
        };
        salt: string;
      },
      {
        publicKey: ArrayBuffer;
        privateKey: ArrayBuffer;
        salt: ArrayBuffer;
        userId?: string;
      }
    >({
      invalidatesTags: [],
      queryFn: async ({ publicKey, privateKey, salt, userId }) => {
        const session = await supabase.auth.getSession();
        if (!session.data.session || session.error) {
          return {
            error: getSupabaseError(session.error || { message: "Session not found" }),
          };
        }
        const user = userId || session.data.session.user.id;

        const payload = {
          legacy_private_key: arrayBufferToHex(privateKey),
          legacy_public_key: arrayBufferToHex(publicKey),
          legacy_salt: arrayBufferToHex(salt),
        };
        const { data, error } = await supabase
          .from("users")
          .update(payload)
          .filter("id", "eq", user)
          .select("privateKey:legacy_private_key, publicKey:legacy_public_key, salt:legacy_salt")
          .single();

        if (error) return { error: getSupabaseError(error) };

        if (!data.publicKey || !data.privateKey || !data.salt) {
          return {
            error: getSupabaseError({
              message: "Keys not found",
            }),
          };
        }

        return {
          data: {
            keyPair: {
              publicKey: data.publicKey,
              privateKey: data.privateKey,
            },
            salt: data.salt,
          },
        };
      },
    }),

    getLegacyKeyPair: builder.query<
      {
        keyPair: {
          publicKey: string;
          privateKey: string;
        };
        salt: string;
      },
      void
    >({
      providesTags: [],
      queryFn: async () => {
        const session = await supabase.auth.getSession();
        if (!session.data.session || session.error) {
          return {
            error: getSupabaseError(session.error || { message: "Session not found" }),
          };
        }

        const { data, error } = await supabase
          .from("users")
          .select("privateKey:legacy_private_key, publicKey:legacy_public_key, salt:legacy_salt")
          .filter("id", "eq", session.data.session.user.id)
          .maybeSingle();

        if (data && data.privateKey && data.publicKey && data.salt) {
          return {
            data: {
              keyPair: {
                publicKey: data.publicKey,
                privateKey: data.privateKey,
              },
              salt: data.salt,
            },
          };
        }

        return {
          error: getSupabaseError(
            error || {
              message: "KeyPair not found. Please generate it first.",
            }
          ),
        };
      },
    }),
  }),
});
