import { KeyPairAndSalt } from "@/types/cryptography";
import { KEY_PAIR_STORE } from "@/utils/hooks/useKeys/constants";
import { deleteAllKeysFromSessionStorage, getItemFromSessionStorage, getKeysFromSessionStorage } from "@/utils/hooks/useKeys/utils";
import { createContext, PropsWithChildren, useEffect, useMemo, useState } from "react";
type RefetchFn = () => Promise<{
  user: KeyPairAndSalt | null;
  org: KeyPairAndSalt | null;
  orgSecret: string | null;
}>;
export type KeyPairContextType = {
  user: KeyPairAndSalt | null;
  org: KeyPairAndSalt | null;
  orgSecret: string | null;
  isLoading: boolean;
  setLoading: (prop: boolean) => void;
  refetch: RefetchFn;
  reset: () => void;
};
type KeyPair = {
  user: KeyPairAndSalt | null;
  org: KeyPairAndSalt | null;
  orgSecret: string | null;
};
const refetchKeys = async () => {
  console.log("Refetching key pairs from SessionStorage");
  try {
    return {
      user: await getKeysFromSessionStorage(KEY_PAIR_STORE.user),
      org: await getKeysFromSessionStorage(KEY_PAIR_STORE.org),
      orgSecret: await getItemFromSessionStorage(KEY_PAIR_STORE.orgSecret)
    };
  } catch (e) {
    console.error("Error while refetching key pairs from SessionStorage", e);
    console.log("Deleting SessionStorage");
    deleteAllKeysFromSessionStorage();
  }
  return {
    user: null,
    org: null,
    orgSecret: null
  };
};
export const KeyPairContext = createContext<KeyPairContextType>({
  user: null,
  org: null,
  orgSecret: null,
  isLoading: false,
  refetch: refetchKeys,
  reset: () => null,
  setLoading: () => null
});
const defaultState = {
  user: null,
  org: null,
  orgSecret: null
};
export const KeyPairProvider = ({
  children
}: PropsWithChildren) => {
  const [keyPair, setKeyPair] = useState<KeyPair>(defaultState);
  const [isLoading, setIsLoading] = useState(false);
  const reset = () => {
    setKeyPair(defaultState);
  };
  const setLoading = (prop: boolean) => setIsLoading(prop);
  const refetch: RefetchFn = async () => {
    const keys = await refetchKeys();
    setKeyPair(keys);
    return keys;
  };
  const keyPairValues = useMemo(() => ({
    user: keyPair?.user || null,
    org: keyPair?.org || null,
    orgSecret: keyPair?.orgSecret || null
  }), [keyPair.user, keyPair.org, keyPair.orgSecret]);
  useEffect(() => {
    let mounted = true;
    const fetchKeyPair = async () => {
      if (!mounted) return;
      await refetch();
    };
    fetchKeyPair();
    return () => {
      mounted = false;
    };
  }, []);
  return <KeyPairContext.Provider value={{
    ...keyPairValues,
    isLoading,
    setLoading,
    refetch,
    reset
  }} data-sentry-element="unknown" data-sentry-component="KeyPairProvider" data-sentry-source-file="KeyPairProvider.tsx">
      {children}
    </KeyPairContext.Provider>;
};