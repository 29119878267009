import { getConfig } from "@/features/branding";
import { HacsOmUploadData } from "@/features/hacs";
import { useGetCurrentGroupIdQuery, useGetGroupsQuery, useGetToolUsageQuery, useIsOwnerQuery } from "@/store/services/supabase";
import { useKeys } from "@/utils/hooks/useKeys";
import { removeFileExtension } from "@/utils/string";
import { ArrowUpOutlined } from "@ant-design/icons";
import { Alert, Button, Divider, Input, Progress, Select, Statistic, Switch, Upload } from "antd";
import classnames from "classnames";
import { useEffect, useState } from "react";
import s from "./PortfolioExportUploadReview.module.scss";
export interface PortfolioExportUploadReviewProps {
  /** Handles whenever a CSV is selected. */
  setData: (data?: HacsOmUploadData) => void;
  /** File data for the chosen CSV upload. */
  data: HacsOmUploadData;
  onBack: () => void;
  onContinue: () => void;
}
export const PortfolioExportUploadReview = ({
  data,
  setData,
  onBack,
  onContinue
}: PortfolioExportUploadReviewProps) => {
  const currentUsage = useGetToolUsageQuery({
    ignoreDateFrom: true
  }).data;
  const {
    data: groups
  } = useGetGroupsQuery();
  const {
    data: currentGroupId
  } = useGetCurrentGroupIdQuery();
  const {
    data: isOwner
  } = useIsOwnerQuery();
  const {
    termsOfService
  } = getConfig().featureSet.docs.legal;
  const [filename, setFilename] = useState(removeFileExtension(data.file.name || ""));
  const [isPrivate, setIsPrivate] = useState(true);
  const [disablePrivateToggle, setDisablePrivateToggle] = useState(true);
  const [keys] = useKeys();
  const [groupId, setGroupId] = useState("");
  useEffect(() => {
    if (currentGroupId?.id) {
      setGroupId(currentGroupId.id);
    }
  }, [currentGroupId]);
  useEffect(() => {
    if (keys.orgSecret) {
      setIsPrivate(false);
      setDisablePrivateToggle(false);
    }
  }, [keys]);
  if (!currentUsage) return <></>;

  // Don't count the header row.
  const uploadedAssetCount = data.lineCount - 1;
  const uploadedAssetPercent = uploadedAssetCount / currentUsage.quota * 100;
  const futureUsage = currentUsage.usage.portfolioExport + uploadedAssetCount;
  const futureUsagePercent = futureUsage / currentUsage.quota * 100;
  const quotaUsedPercent = 1 - (currentUsage.quota - currentUsage.usage.portfolioExport) / currentUsage.quota;
  const showWarning = futureUsagePercent > 80 && futureUsagePercent <= 100;
  const showError = futureUsagePercent > 100;
  const handleContinue = () => {
    setData({
      ...data,
      name: filename,
      groupId: isPrivate && currentGroupId ? currentGroupId.id : groupId,
      isPrivate
    });
    onContinue();
  };
  const groupOpts = groups && groups.map(g => ({
    key: g.id,
    value: g.id,
    label: g.name || ""
  }));
  return <div className={s.fileUploadContainer} data-sentry-component="PortfolioExportUploadReview" data-sentry-source-file="PortfolioExportUploadReview.tsx">
      <div className={s.fileContainer}>
        <Upload listType="text" disabled defaultFileList={[{
        uid: "0",
        status: "done",
        name: data.file.name
      }]} data-sentry-element="Upload" data-sentry-source-file="PortfolioExportUploadReview.tsx" />
      </div>

      <fieldset className={s.fileContainer}>
        <legend>Review Upload</legend>
        <div className={s.statsContainer}>
          <div className={s.statsRowContainer}>
            <div className={s.statsTitle}>Name</div>
            <Input className={s.fileInfoName} value={filename} onChange={val => setFilename(val.target.value)} data-sentry-element="Input" data-sentry-source-file="PortfolioExportUploadReview.tsx" />
          </div>

          <div className={s.statsRowContainer}>
            <div className={s.statsTitle}>Private</div>
            <div className="!col-span-2">
              <Switch value={isPrivate} disabled={disablePrivateToggle} onChange={val => setIsPrivate(val)} data-sentry-element="Switch" data-sentry-source-file="PortfolioExportUploadReview.tsx" />
            </div>
          </div>

          <div className={classnames(s.statsRowContainer, "mb-3 !h-3")}>
            <div className="col-span-2 col-start-2 h-4 text-xs text-gray-500">
              {isPrivate ? "Your portfolio will be only visible to you." : "Your portfolio will be visible to everyone in the group."}
            </div>
          </div>

          {isOwner && !isPrivate && keys.orgSecret && <div className={s.statsRowContainer}>
              <div className={s.statsTitle}>Share with</div>
              <Select className={s.fileInfoName} placeholder="Select group..." options={groupOpts} onChange={e => setGroupId(e)} value={groupId} />
            </div>}

          <div className={s.statsRowContainer} style={{
          marginBottom: "2em"
        }}>
            <div className={s.statsTitle}>Total Assets</div>
            <Statistic value={uploadedAssetCount} className={s.statsValue} data-sentry-element="Statistic" data-sentry-source-file="PortfolioExportUploadReview.tsx" />
          </div>

          <Divider plain orientation="left" style={{
          margin: 0,
          marginBottom: "0.5em",
          color: "var(--color-gray-400)"
        }} orientationMargin={"0"} data-sentry-element="Divider" data-sentry-source-file="PortfolioExportUploadReview.tsx">
            Usage After Upload
          </Divider>

          <div className={s.statsRowContainer}>
            <div className={s.statsTitle}>Assets Used</div>
            <Statistic value={futureUsage} className={`${s.statsValue} ${showWarning && s.warning} ${showError && s.error}`} suffix={`/ ${currentUsage.quota?.toLocaleString() ?? "<Limit Not Set>"}`} data-sentry-element="Statistic" data-sentry-source-file="PortfolioExportUploadReview.tsx" />
            <Statistic value={uploadedAssetCount} valueStyle={{
            color: "var(--color-gray-400)"
          }} prefix={<ArrowUpOutlined />} className={s.statsValue} data-sentry-element="Statistic" data-sentry-source-file="PortfolioExportUploadReview.tsx" />
          </div>

          <div className={s.statsRowContainer}>
            <div className={s.statsTitle}>Assets Used %</div>
            <Statistic value={futureUsagePercent} precision={2} suffix="%" className={`${s.statsValue} ${showWarning && s.warning} ${showError && s.error}`} data-sentry-element="Statistic" data-sentry-source-file="PortfolioExportUploadReview.tsx" />
            <Statistic value={uploadedAssetPercent} precision={2} valueStyle={{
            color: "var(--color-gray-400)"
          }} prefix={<ArrowUpOutlined />} suffix="%" className={s.statsValue} data-sentry-element="Statistic" data-sentry-source-file="PortfolioExportUploadReview.tsx" />
          </div>

          <div className={s.statsRowContainer}>
            <Progress percent={futureUsagePercent} success={{
            percent: quotaUsedPercent,
            strokeColor: "#4B1553"
          }} className={`${s.progressContainer} ${showError && s.error}`} showInfo={false} style={{
            gridColumn: "2 / 4"
          }} data-sentry-element="Progress" data-sentry-source-file="PortfolioExportUploadReview.tsx" />
          </div>
        </div>
      </fieldset>

      {showWarning && <Alert message={<div>
              Your organization is close to the limit of your allocation. Please contact your administrator.{" "}
              <a href={termsOfService} target="_blank" rel="noreferrer">
                Read More
              </a>
            </div>} type="warning" showIcon className={s.alert} />}

      {showError && <Alert message={<div>
              <div>
                <strong>Limit Reached</strong>
              </div>
              Your institution has reached the limit of its annual allocation. The quota will be renewed on the first
              day of the next calendar year. Please upload a smaller file or contact your administrator or the service
              desk.{" "}
              <a href={termsOfService} target="_blank" rel="noreferrer">
                Read More
              </a>
            </div>} type="error" showIcon className={s.alert} />}

      <div className={s.actionButtons}>
        <Button type="text" onClick={onBack} data-sentry-element="Button" data-sentry-source-file="PortfolioExportUploadReview.tsx">
          Back
        </Button>
        <Button type="primary" onClick={handleContinue} disabled={currentUsage.newRun.alwaysEnabled ? false : showError} data-sentry-element="Button" data-sentry-source-file="PortfolioExportUploadReview.tsx">
          Continue
        </Button>
      </div>
    </div>;
};