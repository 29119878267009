import { RemoteJobTable } from "@/features/remote_jobs";
import { store } from "@/store";
import { supabaseApi, useGetCurrentUserQuery, useGetPortfoliosQuery } from "@/store/services/supabase";
import { portfolioExportActions } from "@/store/slices/portfolioExport";
import { PortfolioExportProgressTracker } from "@/tools/aggregate/portfolio-export/classes/PortfolioExportProgressTracker";
import { PortfolioExportJobTableBody } from "@/tools/aggregate/portfolio-export/components/PortfolioExportJobTableBody";
import { PortfolioExportMaxSize } from "@/tools/aggregate/portfolio-export/components/PortfolioExportMaxSize";
import { PortfolioExportTableHeader } from "@/tools/aggregate/portfolio-export/components/PortfolioExportTableHeader";
import { PortfolioExportJobSchema } from "@/tools/aggregate/portfolio-export/types";
import { Tool, ToolProps } from "@/types/tools";
import { useKeys } from "@/utils/hooks/useKeys";
import { decryptData, encryptData, encryptSecret, getSecretKey } from "@/features/cryptography";
import { PortfolioExportPendingKeys } from "@/tools/aggregate/portfolio-export/components/PortfolioExportPendingKeys";
import { PortfolioExportTutorial } from "@/tools/aggregate/portfolio-export/components/PortfolioExportTutorial";
import { PortfolioPasswordInput } from "@/tools/aggregate/portfolio-export/components/PortfolioPasswordInput";
import { arrayBufferToString, hexToArrayBuffer } from "@/utils/crypto";
import { ArrowRightOutlined, Loading3QuartersOutlined, ProjectOutlined } from "@ant-design/icons";
import { useInterval, useLocalStorage } from "@mantine/hooks";
import { useSession } from "@supabase/auth-helpers-react";
import { Alert, AlertProps, Button, Modal, notification, Space, Spin } from "antd";
import { isEqual } from "lodash";
import { FC, useEffect, useRef, useState } from "react";
const PortfolioExportTool = (props: ToolProps) => {
  const [keysReceivedState, setKeysReceivedState] = useLocalStorage<"unset" | "awaiting" | "received">({
    key: "orgKeyReceivedState",
    defaultValue: "unset"
  });
  const [progressTracker, setProgressTracker] = useState<PortfolioExportProgressTracker>();
  const [error, setError] = useState<null | {
    message: string;
    description: string[];
  }>(null);
  const [jobSchemas, _setJobSchemas] = useState<PortfolioExportJobSchema[]>([]);
  const [isJobSchemasLoading, setJobSchemasLoading] = useState(true);
  const [alerts, setAlerts] = useState<PEAlertProps[]>([]);
  const [hasJustReceivedKeys, setHasJustReceivedKeys] = useState(false);
  const [showPendingKeysModal, setShowPendingKeysModal] = useState(false);
  const refJobSchemas = useRef<PortfolioExportJobSchema[]>();
  const refProgressTracker = useRef<PortfolioExportProgressTracker>();
  const [notify, notificationContext] = notification.useNotification();
  const session = useSession();
  const [keyPair] = useKeys();
  const portfolios = useGetPortfoliosQuery();
  const {
    data: currentUser,
    refetch: refetchCurrentUser
  } = useGetCurrentUserQuery();
  const keyPairNotPresent = !keyPair.org;
  interface PEAlertProps extends AlertProps {
    key: string;
  }

  /**
   * Poll for keys every 10 seconds until we have them.
   */
  const updateKeys = async () => {
    if (currentUser?.has_org_keys) {
      refetchCurrentUserInterval.stop();
      return;
    }
    const user = await refetchCurrentUser();
    if (user.data?.has_org_keys) {
      refetchCurrentUserInterval.stop();
      return;
    }
  };
  const refetchCurrentUserInterval = useInterval(updateKeys, 10000);
  useEffect(() => {
    if (!refetchCurrentUserInterval.active && !currentUser?.has_org_keys) {
      refetchCurrentUserInterval.start();
    }
    return refetchCurrentUserInterval.stop;
  }, [refetchCurrentUserInterval]);
  useEffect(() => {
    const currentDate = new Date();
    const newAlerts: PEAlertProps[] = [];

    // When user previously accessed page without key, but now has them, show notice.
    if (keyPair.user && currentUser?.has_org_keys && (keysReceivedState === "awaiting" || hasJustReceivedKeys)) {
      setHasJustReceivedKeys(true);
      setKeysReceivedState("received");
      newAlerts.push({
        key: "received",
        message: "Keys Received",
        description: <>
            Your account has received your organisation&apos;s encryption keys. You now have full access to the
            Portfolio Export tool and can share portfolios within your group.
          </>,
        type: "success",
        showIcon: true,
        closable: !!keyPair.org,
        onClose: () => {
          setHasJustReceivedKeys(false);
        }
      });
    }
    if (keyPair.user && !currentUser?.has_org_keys) {
      // When the user accesses the page without org key, show an awaiting message.
      setKeysReceivedState("awaiting");
      newAlerts.push({
        key: "awaiting",
        message: <Space>
            <span>Awaiting Encryption Keys</span>
            <Spin className="mb-1" indicator={<Loading3QuartersOutlined spin />} size="small" />
          </Space>,
        description: <Space direction="vertical">
            You require your organisation&apos;s encryption keys to enable secure portfolio sharing. In the meantime,
            you can create private portfolios. Please try again later or ask an authorised user from your Organisation
            to log in and share the encryption keys.
            <Button onClick={() => setShowPendingKeysModal(true)} type="link" className="p-0">
              How can I receive my organisation&apos;s encryption keys? <ArrowRightOutlined />
            </Button>
          </Space>,
        type: "info",
        showIcon: true
      });
    }
    if (currentDate <= new Date("2025-03-25")) {
      newAlerts.push({
        key: "noticeLate",
        message: "Customer Notice",
        description: <>
            As part of a platform update all portfolios previously saved to individual user accounts have been deleted.
            From <strong>10th February</strong>, newly created portfolio files will be visible to all users within your
            organisational group.
          </>,
        type: "warning",
        showIcon: true
      });
    }
    setAlerts(newAlerts);
  }, [currentUser, keyPair, keysReceivedState, hasJustReceivedKeys]);
  useEffect(() => {
    props.setIsTutorialDisabled(keyPairNotPresent);
  }, [keyPairNotPresent]);

  /** This ensures that we don't have to wait until state update to get updated value. */
  refJobSchemas.current = jobSchemas;
  refProgressTracker.current = progressTracker;

  /** Wraps _setJobSchemas to ensure the progress tracker is kept up to date. */
  const setJobSchemas = (schemas: PortfolioExportJobSchema[]) => {
    _setJobSchemas(schemas);
    if (refProgressTracker.current) {
      refProgressTracker.current.schemas = schemas;
    }
  };
  useEffect(() => {
    if (!keyPair.user) return;
    const tracker = new PortfolioExportProgressTracker(updateSchema, {
      user: keyPair.user,
      org: keyPair.org
    });
    console.log("org keypair: ", keyPair.org);
    setProgressTracker(tracker);
  }, [keyPair.user, keyPair.org]);
  useEffect(() => {
    if (!jobSchemas.length || !progressTracker) {
      setJobSchemasLoading(false);
      return;
    }
    progressTracker.schemas = jobSchemas;
    progressTracker.progress().catch(err => {
      if (err.name === "AbortError") return;
      console.error("Something went wrong in progressTracker: ", err);
      setError(err);
    });
    setJobSchemasLoading(false);
  }, [jobSchemas, progressTracker]);
  useEffect(() => {
    if (!keyPair.user) return;
    updateSchemas();
  }, [portfolios, keyPair.org, keyPair.user]);

  /**
   * Update a specific schema.
   */
  const updateSchema = (updatedSchema: PortfolioExportJobSchema) => {
    let hasUpdated = false;
    const schemas = (refJobSchemas.current || jobSchemas).map(schema => {
      if (schema.id === updatedSchema.id) {
        hasUpdated = !isEqual(schema, updatedSchema);
        return updatedSchema;
      }
      return schema;
    });
    hasUpdated && setJobSchemas(schemas);
  };

  /**
   * Matches schemas to new portfolio data.
   */
  const updateSchemas = async () => {
    if (portfolios.data === undefined) {
      return;
    }
    const promises = portfolios.data.map(async item => {
      const existingSchema = (refJobSchemas.current || jobSchemas).find(({
        id
      }) => id === item.id);
      const assets = {
        errorCount: item.error || 0,
        totalCount: item.total || 0,
        processedCount: item.success || 0,
        unprocessedCount: item.unprocessed || 0
      };
      const getStatus = async () => {
        if (item.pending === 0 && item.error) return "completed_with_errors";
        if (item.pending === 0) return "completed";
        if (item.total === (item.success || 0) + (item.error || 0) && item.total > 0 && (item.errors_inserted || 0) === (item.error || 0)) {
          return "completed";
        }
        if (existingSchema?.status) return existingSchema.status;
        if (assets.unprocessedCount) {
          store.dispatch(portfolioExportActions.setNewRunTotalInBatchTo({
            id: item.id!,
            count: assets.unprocessedCount
          }));
          return "uploading";
        }
        return "processing";
      };
      console.log(item.secret_enc);
      const secret = item.is_private ? await getSecretKey(keyPair.user!.keyPair.privateKey, item.secret_enc!) : await getSecretKey(keyPair.org!.keyPair.privateKey, item.secret_enc!);
      console.log("secret", secret);
      const schema = {
        id: item.id!,
        name: item.name ? await decryptData(secret, hexToArrayBuffer(item.name)) : "Portfolio Export",
        assets,
        startTime: new Date(item.created_at!).getTime(),
        status: await getStatus(),
        summaryId: item.summary_id || "",
        userId: item.user_id!,
        email: item.email!,
        groupId: item.group_id || "",
        groupName: item.group_name || "",
        secret,
        isPrivate: item.is_private || false
      };
      return schema;
    });
    const schemas: PromiseSettledResult<PortfolioExportJobSchema>[] = await Promise.allSettled(promises);
    console.log("schemas: ", schemas);
    const succeededSchemas = schemas.filter((p): p is PromiseFulfilledResult<PortfolioExportJobSchema> =>
    // exclude errored portfolios; presumably could not be decrypted
    p.status === "fulfilled" && (
    // only include completed portfolios for other users
    // to avoid multiple batch uploads & progress connection
    p.value.userId === session?.user.id || ["completed", "success", "completed_with_errors"].includes(p.value.status))).map(s => s.value);
    setJobSchemas(succeededSchemas);
  };
  return <>
      {process.env.NEXT_PUBLIC_THEME_NAME?.startsWith("hkma") && <PortfolioExportMaxSize />}
      {notificationContext}

      <Modal closable onCancel={() => setShowPendingKeysModal(false)} width={540} open={showPendingKeysModal} footer={null} data-sentry-element="Modal" data-sentry-source-file="index.tsx">
        <PortfolioExportPendingKeys data-sentry-element="PortfolioExportPendingKeys" data-sentry-source-file="index.tsx" />
      </Modal>

      {alerts.map(({
      key,
      ...alert
    }) => <Alert key={key} className="mb-6 p-4" {...alert} />)}

      {error && <Alert message={error.message || "An unexpected error has occurred"} description={error.description ? <ul>
                {error.description.map((err, index) => <li key={index}>{err}</li>)}
              </ul> : "Please refresh your browser & try again. If the error persists, please contact support."} type="error" closable />}

      {keyPair.user ? <>
          {currentUser?.has_org_keys && !keyPair.org ? <PortfolioPasswordInput prompt={hasJustReceivedKeys ? "You have been granted Encryption Keys. Enter your password to confirm the exchange and unlock  portfolios shared with your group." : undefined} isLoading={keyPair.isLoading} /> : <RemoteJobTable<PortfolioExportJobSchema> headerComponent={PortfolioExportTableHeader as FC} jobSchemas={jobSchemas} onRunStart={console.log} setJobSchemas={setJobSchemas}>
              <PortfolioExportJobTableBody schemas={jobSchemas} loading={isJobSchemasLoading} updateSchema={updateSchema} onDelete={async (id: string) => {
          const action = supabaseApi.endpoints.deletePortfolio.initiate(id);
          await store.dispatch(action);
          const schemas = refJobSchemas.current || jobSchemas;
          setJobSchemas(schemas.filter(schema => id !== schema.id));
        }} onEdit={async props => {
          if (!keyPair.user) return;
          let encryptedSecret = "";
          if (props.isPrivate) {
            console.log("privateee");
            encryptedSecret = arrayBufferToString(await encryptSecret({
              publicKey: keyPair.user.keyPair.publicKey,
              secret: props.schema.secret.secretAndSalt
            }));
          } else {
            if (keyPair.orgSecret && keyPair.org?.keyPair.publicKey) {
              encryptedSecret = arrayBufferToString(await encryptSecret({
                publicKey: keyPair.org.keyPair.publicKey,
                secret: props.schema.secret.secretAndSalt
              }));
            }
          }
          const details = {
            id: props.schema.id,
            name: await encryptData(props.schema.secret, props.name),
            groupId: props.groupId,
            isPrivate: props.isPrivate,
            secretEnc: encryptedSecret
          };
          const action = supabaseApi.endpoints.updatePortfolioDetails.initiate(details);
          const res = await store.dispatch(action);
          if ("error" in res) {
            console.error(res.error);
            notify.error({
              message: "Error moving portfolio",
              description: "message" in res.error ? res.error.message : "Something went wrong. Please try again."
            });
          } else {
            notify.success({
              message: "Portfolio updated!"
            });
          }
        }} onError={async (err, portfolioId) => {
          const action = supabaseApi.endpoints.deletePortfolio.initiate(portfolioId);
          await store.dispatch(action);
          const schemas = refJobSchemas.current || jobSchemas;
          setJobSchemas(schemas.filter(schema => portfolioId !== schema.id));
          setError({
            message: "Error while uploading your portfolio",
            description: [...err]
          });
        }} />
            </RemoteJobTable>}
        </> : <PortfolioPasswordInput isLoading={keyPair.isLoading} />}

      <PortfolioExportTutorial onClose={() => props.setIsTutorialOpen(false)} open={props.isTutorialOpen} setState={
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    () => {}} data-sentry-element="PortfolioExportTutorial" data-sentry-source-file="index.tsx" />
    </>;
};
export default {
  id: "portfolio-export",
  category: "aggregate",
  keyPrefix: "aggregate.portfolioExport",
  icon: <ProjectOutlined />,
  render: (props: ToolProps) => <PortfolioExportTool {...props} />,
  hasTutorial: true,
  showUsage: true
} as Tool;