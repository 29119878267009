import { InteractiveTutorial, InteractiveTutorialProps } from "@/features/interactive_tutorial";
import { useGetOrgQuery } from "@/store/services/supabase";
import { Col, Image, Row, Space, TourStepProps } from "antd";
export const PortfolioExportTutorial = (props: InteractiveTutorialProps<Record<any, any>>) => {
  const {
    data: org
  } = useGetOrgQuery();
  const assetLevelSteps: TourStepProps[] = org?.is_large_bank ? [{
    title: "Asset-Level Results",
    description: <Space direction="vertical">
              <p>
                Click <b>Asset Level</b> to download a .csv file with detailed results for each asset under different
                emissions scenarios.
              </p>
              <Image alt="Indication to click the 'Asset Level' button on a complete portfolio." src="/img/tours/portfolio-export/asset_level_b.png" />
            </Space>
  }] : [{
    title: "Asset-Level Results",
    description: <Row gutter={[8, 8]}>
              <Col span={16}>
                <div className="flex h-full flex-col justify-between">
                  <p>
                    Click <b>Asset-Level</b> to view options to download detailed results for each asset.
                  </p>
                  <Image className="max-w-full pb-2" alt="Indication to click the 'Asset Level' button on a complete portfolio." src="/img/tours/portfolio-export/asset_level_a1.png" />
                </div>
              </Col>
              <Col span={8}>
                <Image className="max-h-[32rem]" alt="Display of Asset-Level download options." src="/img/tours/portfolio-export/asset_level_a2.png" />
              </Col>
            </Row>
  }];
  const steps: TourStepProps[] = [{
    title: "Click 'Analyse New Portfolio'.",
    description: <Space direction="vertical">
          <p>
            Access the <b>Portfolio Export</b> tool from the side menu and click <b>Analyse New Portfolio</b>.
          </p>
          <Image alt="Indication to click the button titled 'Analyse New Portfolio'." src="/img/tours/portfolio-export/navigate.png" />
        </Space>
  }, {
    title: "Click 'Download Template .csv'.",
    description: <Space direction="vertical">
          <p>
            Download the template by clicking <b>Download Template .csv</b>. Enter the details for each asset in your
            portfolio, such as address or coordinates and build year. Be careful to follow the template structure and
            other input rules.
          </p>
          <div className="flex w-full justify-center">
            <Image className="max-w-[24rem]" alt="Indication to click the 'Download Template .csv' button to obtain a demonstration file." src="/img/tours/portfolio-export/template.png" />
          </div>
        </Space>
  }, {
    title: "Upload",
    description: <Space direction="vertical">
          <p>
            Upload your completed input file by dragging and dropping it or selecting it in the <b>Select .csv file</b>{" "}
            area.
          </p>
          <div className="flex w-full justify-center">
            <Image className="max-w-[24rem]" alt="Indication to upload file using 'Select .csv file' box." src="/img/tours/portfolio-export/upload.png" />
          </div>
        </Space>
  }, {
    title: "Review",
    description: <Space direction="vertical">
          <p>
            A confirmation screen will appear, allowing you to rename your portfolio, review the total asset count, and
            check your organisation&apos;s usage quota. You can choose to make the portfolio private or share it with
            your group. Click <b>Continue</b> to run the analysis or <b>Back</b> to re-upload the file.
          </p>
          <div className="flex w-full justify-center">
            <Image className="max-w-[24rem]" alt="Confirmation step before continuing with upload." src="/img/tours/portfolio-export/review.png" />
          </div>
        </Space>
  }, {
    title: "Processing",
    description: <Space direction="vertical">
          <p>
            The Portfolio Analysis will begin, and your portfolio file will show a blue <b>Processing</b> status.
            Depending on the size of the portfolio, this may take several minutes. Once processing is complete, the
            status changes to a green tick.
          </p>
          <p>
            <i>
              NOTE: To enable browser notifications for completed exports, click the Alarm Bell icon and follow the
              prompts.
            </i>
          </p>
          <div className="flex w-full justify-center">
            <Space direction="vertical">
              <Image alt="Indication to allow portfolio to complete processing." src="/img/tours/portfolio-export/process_a.png" />
              <Image alt="Visual of a complete portfolio row." src="/img/tours/portfolio-export/process_b.png" />
            </Space>
          </div>
        </Space>
  }, {
    title: "Summary Results",
    description: <Space direction="vertical">
          <p>
            Click <b>Summary</b> to download a .csv file with an overview of aggregated portfolio metrics.
          </p>
          <Image alt="Indication to click the 'Summary' button on a complete portfolio." src="/img/tours/portfolio-export/summary.png" />
        </Space>
  }, ...assetLevelSteps, {
    title: "Editing A Portfolio",
    description: <Space direction="vertical">
          <p>
            Update the file name and sharing settings of an existing portfolio by clicking the three dots next to the
            file and selecting <b>Edit</b>.
          </p>
          <p>
            Private portfolios are visible only to you, while shared portfolios are accessible to your group. Only
            Account Owners can share portfolios across all groups within an organisation.
          </p>
          <div className="flex w-full justify-center">
            <Space align="center" direction="vertical">
              <Image className="max-w-[32rem]" alt="Display of portfolio editing modal." src="/img/tours/portfolio-export/editing_a.png" />
              <Image alt="Indication to click the Edit button in the extended menu for a portfolio." src="/img/tours/portfolio-export/editing_b.png" />
            </Space>
          </div>
        </Space>
  }, {
    title: "Deleting A Portfolio",
    description: <Space direction="vertical">
          <p>
            Delete a portfolio by clicking the three dots next to the file and selecting <b>Delete</b>.
          </p>
          <p>Deleting a shared portfolio will permanently remove it for all members of your organisation.</p>
          <div className="flex w-full justify-center">
            <Space align="center" direction="vertical">
              <Image className="max-w-[32rem]" alt="Display of portfolio deletion popup." src="/img/tours/portfolio-export/deleting_a.png" />
              <Image alt="Indication to click the Delete button in the extended menu for a portfolio." src="/img/tours/portfolio-export/deleting_b.png" />
            </Space>
          </div>
        </Space>
  }];
  return <InteractiveTutorial<Record<any, any>> isWide={true} steps={steps} {...props} data-sentry-element="InteractiveTutorial" data-sentry-component="PortfolioExportTutorial" data-sentry-source-file="PortfolioExportTutorial.tsx" />;
};