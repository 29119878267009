import { encryptData } from "@/features/cryptography";
import { SupabaseDatabase } from "@/features/supabase";
import { store } from "@/store";
import { supabaseApi } from "@/store/services/supabase";
import { dispatchWithRetries } from "@/store/services/supabase/utils";
import { portfolioExportActions } from "@/store/slices/portfolioExport";
import { PortfolioExportIdFactory } from "@/tools/aggregate/portfolio-export/classes/PortfolioExportIdFactory";
import { PortfolioExportSupabaseTransformStream } from "@/tools/aggregate/portfolio-export/classes/PortfolioExportSupabaseTransformStream";
import { SecretSalt } from "@/types/cryptography";

type Portfolio = SupabaseDatabase["public"]["Tables"]["portfolios"]["Row"];

/**
 * Data for setting up a portfolio in Supabase.
 */
interface PortfolioDetails {
  name: ArrayBuffer;
  startTime: number;
  encryptedSecret: string;
  groupId?: string;
  isPrivate?: boolean;
}

type StreamToSupabaseResponse = {
  portfolioId: string;
};

interface PortfolioExportSupabaseResponseSuccess<T> {
  type: "success";
  data: T;
}

type PortfolioExportSupabaseResponseFailure = {
  type: "failure";
  errors: string[];
};

export type PortfolioExportSupabaseResponse<T> =
  | PortfolioExportSupabaseResponseSuccess<T>
  | PortfolioExportSupabaseResponseFailure;

/** Maximum number of lines to send to Supabase at once. */
const MAX_REQUEST_LINES = 100;

/**
 * Pipes a stream through a transformer and spits results at Supabase.
 * @param stream - Stream of CSV file selected for upload.
 * @param idFactory - ID builder for individual lines.
 */
export const streamToSupabase = async (
  stream: ReadableStream,
  idFactory: PortfolioExportIdFactory,
  details: PortfolioDetails,
  secret: SecretSalt
): Promise<PortfolioExportSupabaseResponse<StreamToSupabaseResponse>> => {
  const supabaseTransformer = new PortfolioExportSupabaseTransformStream(idFactory);
  const reader = stream.pipeThrough(supabaseTransformer).getReader();
  store.dispatch(portfolioExportActions.setNewRunTotalInSupabaseTo(0));

  console.log("Setting up portfolio:", details.name);

  const addPortfolioResponse = await addPortfolio(details);

  if (addPortfolioResponse.type === "failure") {
    return addPortfolioResponse;
  }

  const portfolioId = addPortfolioResponse.data.id;
  let lines: any[] = [];

  const processLines = async (lines: any[]) => {
    return await Promise.all(
      lines.map(async (line) => ({
        encrypted_id: await encryptData(secret, line.id as string),
        portfolio_id: portfolioId,
        data: await encryptData(secret, line.data),
      }))
    );
  };

  let isDone = false;
  let response: PortfolioExportSupabaseResponse<any> = {
    type: "success",
    data: {
      portfolioId,
    },
  };
  while (!isDone) {
    const { done, value } = await reader.read();

    // If we have no more lines, we send the final set of data.
    if (done) {
      isDone = true;

      await processLines(lines).then(async (data) => {
        console.log(`Uploading ${data.length} assets`);

        return await addPortfolioAssets(data).then((res) => {
          response = res;
          store.dispatch(portfolioExportActions.incrementNewRunTotalInSupabaseBy(data.length));

          return res;
        });
      });

      continue;
    }

    // Send data only when we reach a set number of lines.
    if (lines.length >= MAX_REQUEST_LINES) {
      await processLines(lines).then(async (data) => {
        console.log(`Uploading ${data.length} assets`);

        return await addPortfolioAssets(data).then((res) => {
          response = res;
          store.dispatch(portfolioExportActions.incrementNewRunTotalInSupabaseBy(data.length));

          return res;
        });
      });

      lines = [];
    }

    // Exit if addPortfolioAssets fails

    lines.push(value);
  }

  console.log("Response:", response);

  if (response.type !== "success") {
    await store
      .dispatch(supabaseApi.endpoints.deletePortfolio.initiate(portfolioId))
      .unwrap()
      .then(() => console.log("Portfolio deleted."));

    return response;
  }

  if (response.type === "success") {
    store.dispatch(supabaseApi.util.invalidateTags([{ type: "Portfolio", id: "List" }]));
  }

  return response;
};

type AddPortfolioReturnType = PortfolioExportSupabaseResponse<Portfolio>;
const addPortfolio = async (details: PortfolioDetails): Promise<AddPortfolioReturnType> => {
  const action = supabaseApi.endpoints.addPortfolio.initiate({
    name: details.name,
    groupId: details.groupId,
    encryptedSecret: details.encryptedSecret,
    isPrivate: details.isPrivate,
  });

  return dispatchWithRetries<Portfolio>({ action, maxRetries: 10, timeout: 5000 })
    .then((data) => {
      return {
        type: "success",
        data: data,
      } as AddPortfolioReturnType;
    })
    .catch((e) => ({
      type: "failure",
      errors: [JSON.stringify(e)],
    }));
};

type AddPortfolioAssetsParams = {
  encrypted_id: ArrayBuffer;
  portfolio_id: string;
  data: ArrayBuffer;
}[];
type AddPortfolioAssetsReturnType = PortfolioExportSupabaseResponse<{ id: string }[]>;
const addPortfolioAssets = async (processed: AddPortfolioAssetsParams): Promise<AddPortfolioAssetsReturnType> => {
  const action = supabaseApi.endpoints.addPortfolioAssets.initiate(processed);

  return dispatchWithRetries<{ id: string }[]>({ action, maxRetries: 10, timeout: 5000 })
    .then((data) => {
      return {
        type: "success",
        data: data,
      } as AddPortfolioAssetsReturnType;
    })
    .catch((e) => ({
      type: "failure",
      errors: [JSON.stringify(e)],
    }));
};
