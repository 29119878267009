// For bytea columns
export const hexToArrayBuffer = (key: string): ArrayBuffer => {
  const buffer = Buffer.from(key.slice(2), "hex");
  return buffer.buffer.slice(buffer.byteOffset, buffer.byteOffset + buffer.byteLength) as ArrayBuffer;
};

// For bytea columns
export const arrayBufferToHex = (buffer: ArrayBuffer) => {
  return "\\x" + Buffer.from(buffer).toString("hex");
};

export const stringToArrayBuffer = (key: string) => {
  const binaryString = window.atob(key);
  const length = binaryString.length;
  const arrayBuffer = new ArrayBuffer(length);
  const uint8Array = new Uint8Array(arrayBuffer);
  for (let i = 0; i < length; i++) {
    uint8Array[i] = binaryString.charCodeAt(i);
  }
  return arrayBuffer;
};

export const arrayBufferToString = (buffer: ArrayBuffer) => {
  const uint8Array = new Uint8Array(buffer);
  let binaryString = "";
  uint8Array.forEach((byte) => {
    binaryString += String.fromCharCode(byte);
  });
  return window.btoa(binaryString);
};
