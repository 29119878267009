import { KEY_PAIR_ALGORITHM } from "../systems/asymmetric";

/**
 * Converts key pair to string and stores in local storage.
 * @param name - Local storage key.
 * @param key - Private/public key.
 * @returns void
 */
export async function addKeyInSessionStorage(name: string, key: CryptoKey) {
  crypto.subtle.exportKey("jwk", key).then((k) => {
    sessionStorage.setItem(name, JSON.stringify(k));
  });
}

/**
 * Get key stored as string in local storage and converts back to CryptoKey.
 * @param name - Local storage key.
 * @param keyUsage - ["encrypt"] for public key and ["decrypt"] for private key.
 * @returns void
 */
export async function getKeyFromSessionStorage(name: string, keyUsage: KeyUsage[]): Promise<CryptoKey> {
  const key = sessionStorage.getItem(name);
  if (!key) throw new Error("Couldn't find the key in local storage.");

  return await crypto.subtle.importKey("jwk", JSON.parse(key), KEY_PAIR_ALGORITHM, true, keyUsage);
}
