import {
  differenceInDays,
  differenceInHours,
  differenceInMinutes,
  differenceInMonths,
  differenceInYears,
  format,
  formatDistance,
  isBefore,
  isToday,
  isYesterday,
  parseISO,
  set,
  sub,
} from "date-fns";

/**
 * Datetime string is same format used in BLAZE.
 */

/**
 * Builds a readable datetime string.
 * @param date - Date instance.
 * @returns Readable datetime string.
 */
export const formatReadableDateTime = (date: Date): string => {
  const [d, t] = formatStandardDateTime(date).split("-");

  return [
    `${d.slice(0, 4)}/${d.slice(4, 6)}/${d.slice(6, 8)}`,
    `${t.slice(0, 2)}:${t.slice(2, 4)}:${t.slice(4, 6)}`,
  ].join(" ");
};

/**
 * Builds a standard datetime string.
 * @param date - Date instance.
 * @returns Formatted datetime string.
 */
export const formatStandardDateTime = (date: Date): string => {
  return `${formatStandardDate(date)}-${formatStandardTime(date)}`;
};

/**
 * Builds a standard date string.
 * @param date - Date instance.
 * @returns Formatted date string.
 */
const formatStandardDate = (date: Date): string => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");

  return `${year}${month}${day}`;
};

/**
 * Builds a standard time string.
 * @param date - Date instance.
 * @returns Formatted time string.
 */
const formatStandardTime = (date: Date): string => {
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");
  const seconds = String(date.getSeconds()).padStart(2, "0");

  return `${hours}${minutes}${seconds}`;
};

/**
 * Builds relative date string if less than a day ago.
 * Otherwise, builds a standard datetime string.
 * @param Date - Date instance | string.
 * @returns Formatted relative or standard time string.
 */
export const formatRelativeDate = (date: Date | string): string => {
  const dateInIsoFormat = typeof date === "string" ? parseISO(date) : date;

  if (isToday(dateInIsoFormat)) {
    return `Today, ${format(dateInIsoFormat, "h:mmaaa")}`;
  }

  if (isYesterday(dateInIsoFormat)) {
    return `Yesterday, ${format(dateInIsoFormat, "h:mmaaa")}`;
  }

  if (isBefore(sub(new Date(), { days: 1 }), dateInIsoFormat)) {
    return `${formatDistance(dateInIsoFormat, new Date())} ago`;
  }

  return format(dateInIsoFormat, "dd MMM yyyy, h:mmaaa");
};

export const getDaysAgo = (date: Date | string): string => {
  const dateInIsoFormat = typeof date === "string" ? parseISO(date) : date;

  const now = new Date();
  const years = differenceInYears(now, dateInIsoFormat);
  const months = differenceInMonths(now, dateInIsoFormat);
  const days = differenceInDays(now, dateInIsoFormat);
  const hours = differenceInHours(now, dateInIsoFormat);
  const minutes = differenceInMinutes(now, dateInIsoFormat);

  if (years > 0) return `${years}y`;
  if (months > 0) return `${months}mo`;
  if (days > 0) return `${days}d`;
  if (hours > 0) return `${hours}h`;

  return `${minutes}m`;
};

export const formatDate = (date: Date | string): string => {
  const dateInIsoFormat = typeof date === "string" ? parseISO(date) : date;

  return format(dateInIsoFormat, "dd MMM yyyy, h:mmaaa");
};

export const getCurrentDayNumber = () => {
  const currentDate = new Date();
  const startOfYear = new Date(currentDate.getFullYear(), 0, 0);
  const dayNumber = Math.floor((Number(currentDate) - Number(startOfYear)) / (24 * 60 * 60 * 1000));

  return dayNumber;
};

export const formatTimestamp = (timestamp: string) => new Date(timestamp).toLocaleString();

export const subtractTodayWith = (duration: Duration) => {
  return sub(new Date().setHours(0, 0, 0, 0), { ...duration })
    .toISOString()
    .toString();
};

export const getStartOf = ({ date, duration }: { date?: Date; duration: "month" | "year" }) => {
  const currentDate = date || new Date();
  switch (duration) {
    case "month":
      return set(currentDate, { date: 1, hours: 0, minutes: 0, seconds: 0, milliseconds: 0 }).toISOString().toString();
    case "year":
      return set(currentDate, { month: 0, date: 1, hours: 0, minutes: 0, seconds: 0, milliseconds: 0 })
        .toISOString()
        .toString();
    default:
      return set(currentDate, { date: 1, hours: 0, minutes: 0, seconds: 0, milliseconds: 0 }).toISOString().toString();
  }
};

export const getUTCDate = (year: number, month: number, day: number) => {
  return new Date(Date.UTC(year, month, day)).toISOString();
};

export const buildDatesByMonthForYear = () => {
  return Array.from(Array(12).keys()).map((i) => getUTCDate(2024, i, 1));
};
