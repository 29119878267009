import { KeyPairSessionStorage, KEY_PAIR_STORE } from "@/utils/hooks/useKeys/constants";

import { exportKey, importKey } from "@/features/cryptography";
import { KeyPairAndSalt } from "@/types/cryptography";
import { arrayBufferToString, stringToArrayBuffer } from "@/utils/crypto";

export async function deleteAllKeysFromSessionStorage() {
  sessionStorage.removeItem(KEY_PAIR_STORE.user.public);
  sessionStorage.removeItem(KEY_PAIR_STORE.user.private);
  sessionStorage.removeItem(KEY_PAIR_STORE.user.salt);
  sessionStorage.removeItem(KEY_PAIR_STORE.org.public);
  sessionStorage.removeItem(KEY_PAIR_STORE.org.private);
  sessionStorage.removeItem(KEY_PAIR_STORE.org.salt);
  sessionStorage.removeItem(KEY_PAIR_STORE.orgSecret);
}

export async function getKeysFromSessionStorage(keys: KeyPairSessionStorage): Promise<KeyPairAndSalt | null> {
  console.log("getKeysFromSessionStorage: ", keys);
  const keyPair = {
    public: window.atob(sessionStorage.getItem(keys.public) || ""),
    private: window.atob(sessionStorage.getItem(keys.private) || ""),
    salt: window.atob(sessionStorage.getItem(keys.salt) || ""),
  };

  if (!keyPair.private || !keyPair.public || !keyPair.salt) {
    return null;
  }

  const publicKey = importKey(keyPair.public, ["encrypt"]);
  const privateKey = importKey(keyPair.private, ["decrypt"]);
  const salt = stringToArrayBuffer(keyPair.salt);

  const values = await Promise.all([privateKey, publicKey, salt]).then((values) => ({
    keyPair: {
      privateKey: values[0],
      publicKey: values[1],
    },
    salt: values[2],
  }));

  return values;
}

export async function setKeysInSessionStorage(keys: KeyPairSessionStorage, values: KeyPairAndSalt) {
  console.log("setKeysFromSessionStorage: ", keys);
  await exportKey(values.keyPair.privateKey).then((k) =>
    sessionStorage.setItem(keys.private, window.btoa(JSON.stringify(k)))
  );
  await exportKey(values.keyPair.publicKey).then((k) =>
    sessionStorage.setItem(keys.public, window.btoa(JSON.stringify(k)))
  );
  sessionStorage.setItem(keys.salt, window.btoa(arrayBufferToString(values.salt)));
}

export async function setItemInSessionStorage(key: string, value: string) {
  sessionStorage.setItem(key, window.btoa(value));
}

export async function getItemFromSessionStorage(key: string): Promise<string | null> {
  const item = sessionStorage.getItem(key);
  return item ? window.atob(item) : item;
}
