import { getConfig } from "@/features/branding";
import { GetToolUsageResponse, useIsOwnerQuery } from "@/store/services/supabase";
import { ArrowRightOutlined } from "@ant-design/icons";
import { Alert, Button } from "antd";
const DetailsButton = () => {
  const {
    termsOfService
  } = getConfig().featureSet.docs.legal;
  return <a href={termsOfService} target="_blank" rel="noreferrer" data-sentry-component="DetailsButton" data-sentry-source-file="PortfolioExportFairUseWarning.tsx">
      <Button size="small" type="text" data-sentry-element="Button" data-sentry-source-file="PortfolioExportFairUseWarning.tsx">
        Details {<ArrowRightOutlined />}
      </Button>
    </a>;
};
const PortfolioExportFairUseWarning = ({
  data
}: {
  data: GetToolUsageResponse;
}) => {
  const {
    data: isOwner,
    isFetching,
    isLoading
  } = useIsOwnerQuery();

  // Do not show if in process of loading data.
  if (isFetching || isLoading) {
    return <></>;
  }

  // If max portfolio size not set
  if (data.quota === 0) {
    if (isOwner) return <></>;
    return <Alert type="error" className="mt-4" showIcon action={<DetailsButton />} description="Your organisation's portfolio size limit has not been configured by your administrator. Please request them to visit this page to resolve it." message="Portfolio size not configured" />;
  }
  const remainingAssets = data.quota - data.usage.total;
  const quotaUsedPercent = (1 - (data.quota - data.usage.total) / data.quota).toFixed(2);
  return <>
      {data.newRun.warning === "limit" && <Alert type="warning" className="mt-4" showIcon action={<DetailsButton />} message={<div>
              Your organisation has used <strong>{quotaUsedPercent}%</strong> of your allocation. You have{" "}
              <strong>{remainingAssets} assets</strong> remaining. Please contact your administrator.
            </div>} />}
      {data.newRun.warning === "error" && <Alert type="error" className="mt-4" showIcon action={<DetailsButton />} message={<div>
              Your organisation has used <strong>{quotaUsedPercent}%</strong> of your allocation. You are{" "}
              <strong>{-remainingAssets} assets over your limit</strong>. Please contact your administrator.
            </div>} />}
    </>;
};
export default PortfolioExportFairUseWarning;